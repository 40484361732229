.homeContent{
  &--BlocImgLeft {

    margin-top: 50px;
    margin-bottom: 80px;

    &-BlackBox{
      background-color: #000;
      color: #FFF;
      padding: 30px 30px 25px 30px;

      hr {
        margin-top: 0.75rem;
        border: 0;
        border-top: 3px solid #FFF;
        width: 40px;
        margin-left: 0px;
      }

      span{
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    &-TextBox{
      font-size: 17px;
      font-weight: 300;

      span{
        font-weight: 700;
        font-size: 17px;
        padding-bottom: 10px;
        display: block;
      }
    }
  }
  &--BlocImgRight {
    margin-top: 50px;
    margin-bottom: 80px;

    &-TextBox{
      font-size: 1.308rem;
      font-weight: 300;

      span{
        font-weight: 700;
        font-size: 1.308rem;
        padding-bottom: 10px;
        display: block;
      }
    }

    .video-container {
      position: relative;
      padding-bottom: 56.25%;
      /* ratio 16/9 */
      height: 0;
      overflow: hidden;
      clear: both;
    }
    
    .video-container iframe,
    .video-container object,
    .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

  @include media-breakpoint-down(sm) {
    &--BlocImgLeft {
  
      &-BlackBox{
        background-color: #000;
        color: #FFF;
        padding: 20px;
  
        span{
          font-size: 1.2rem;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      &-TextBox{
        font-size: 1rem;
        font-weight: 300;
  
        span{
          font-weight: 700;
          font-size: 1.4rem;
          padding-bottom: 10px;
          display: block;
        }
      }
    }
    &--BlocImgRight {
      margin-top: 50px;
      margin-bottom: 80px;
  
      &-TextBox{
        font-size: 1rem;
        font-weight: 300;
  
        span{
          font-weight: 700;
          font-size: 1.4rem;
          padding-bottom: 10px;
          display: block;
        }
      }
    }
  }

}







.homeTpl1{
    padding-top: 50px;
    padding-bottom: 50px;
    background-size: cover;

    @include media-breakpoint-down(sm) {
        padding-top: 20px !important;
    }   

    h2{
      display: inline;
      background-color: white;
      padding-right: 10px;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: bold;
      a{
        color: #000;
        &:hover{
          text-decoration: none;
        }
      }
    }
    hr{
      margin-top: -8px;
      border-top: 2px solid rgba(0, 0, 0, 1);
    }
    &_Img{
        background-size: cover;
    }
    
    .TitleNumber{
      margin-bottom: 15px;
      padding-right: 15px;
      span{
        font-size: 2.69230769rem;
        font-weight: 800;
        border-bottom: 3px solid #000;
      }
    }
    .bloc2photo{
      margin-left: -15px;

      img{
        width:48%;
      }
    }
  
    @include media-breakpoint-down(sm) {
      background-image: none !important;

      .TitleNumber{
        margin-bottom: 15px;
        padding-right: 5px;
        display: inline-block;
        span{
          font-size: 1.69230769rem;
          font-weight: 800;
          border-bottom: 3px solid #000;
        }
      }
      h2{
        font-size: 20px;
      }
    }
  
}
.homeTpl2{
    padding-top: 50px;
    padding-bottom: 50px;
    background-size: cover;

    @include media-breakpoint-down(sm) {
        padding-top: 20px !important;
    }   

    h2{
      display: inline;
      background-color: white;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: bold;
      a{
        color: #000;
        &:hover{
          text-decoration: none;
        }
      }
    }
    hr{
      margin-top: -8px;
      border-top: 2px solid rgba(0, 0, 0, 1);
    }
    &_Img{
        background-size: cover;
    }

    &_Txt{
        text-align: right;
        padding-right: 25px;
    }
    
    .TitleNumber{
      margin-bottom: 15px;
      padding-left: 15px;
      span{
        font-size: 2.69230769rem;
        font-weight: 800;
        border-bottom: 3px solid #000;
      }
    }
    .bloc2photo{
      margin-left: 10px;

      img{
        width:48%;
      }
    }
  
    @include media-breakpoint-down(sm) {
      background-image: none !important;

      &_Txt{
        text-align: left;
        padding-right: 15px !important;
        }

      .TitleNumber{
        margin-bottom: 15px;
        padding-left: 0px;
        padding-right: 5px;
        display: inline-block;
        span{
          font-size: 1.69230769rem;
          font-weight: 800;
          border-bottom: 3px solid #000;
        }
      }
      h2{
        font-size: 20px;
      }
    }
  
}
.homeTpl2, .homeTpl1{
    .linkCreditD{
      position: absolute;
      left: 0px;
      bottom: 0px;
      font-size: 0.76923077rem;
      font-style: italic;
      color: #000;
      text-decoration: underline;
    }
    .linkCreditG{
      position: absolute;
      right: 0px;
      bottom: 0px;
      font-size: 0.76923077rem;
      font-style: italic;
      color: #000;
      text-decoration: underline;
    }
  }
  
.knowMore{
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
    color: #000;
    text-transform: uppercase;
}



.homeLastConstruction{
    padding-top: 25px;
    background-color: #e5e5e5;
    margin-bottom: 50px;

    h2 {
        text-transform: uppercase;
        font-size: 24px;
        font-weight: bold;
    }

    &_Img{
        bottom: -25px;

        &--item{
            background-size: cover;
        }
    }

    h3 {
        font-size: 20px;
        font-weight: 300;
        line-height: 1.2;
        color: #3b2020;

        a{
          color: #000;
          &:hover{
            text-decoration: none;
          }
        }
      }

     p {
        font-size: 13px;
        font-weight: normal;
        line-height: 1.46;
        color: #000000;
        margin-bottom: 15px;
        
        a{
          color: #000;
          &:hover{
            text-decoration: none;
          }
        }
      }
}


// **********     GoogleMap   **********************
// ***************************************************
.homeGoogleMap{

  margin-top: 30px;
  padding-bottom: 30px;

  hr{
    margin-top: 0.75rem;
    border: 0;
    border-top: 3px solid #000;
    width: 40px;
    margin-left: 0px;
  }

  .tpl01-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .div-flottant {
    left: -80px;
    background-color: #fff;
    z-index: 2;
    padding: 25px;
  }

  h2, p{
    width: calc(100% + 80px);
  }

  h2{
    font-size: 1.69230769rem;
    font-weight: bold;
    text-transform: uppercase;
    a{
      color: #000;
      text-decoration: none;
      &:hover{
        text-decoration: none;
      }
    }
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  .TitleNumber{
    margin-bottom: 15px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  }
  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: left;
    text-decoration: underline;
    padding-top: 3px;

    a{
      color: #000;
    }
  }


  @include media-breakpoint-down(sm) {
    .div-flottant {
      left: 0px !important;
      padding: 15px;
    }
    h2, p{
      width: 100% !important;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }

}


// **********     SWIPER   **********************
// ***************************************************
.slideLogos{


  h2{
    font-size: 1.69230769rem;
    font-weight: bold;
    text-transform: uppercase;
    a{
      color: #000;
      text-decoration: none;
      &:hover{
        text-decoration: none;
      }
    }
  }

    .swiper-container {
      padding: 0 35px !important;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      overflow: hidden;
      z-index: 1;
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
      left: 0px;
      right: auto;
      border: none;
      background-color: #FFF;
      height: 65px;
      top: 22px;
      outline: none;
      color: #000;
    }
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
      right: 0px;
      left: auto;
      border: none;
      background-color: #FFF;
      height: 65px;
      top: 22px;
      outline: none;
      color: #000;
    }
  }



  .homeActus{
    background-color: #e5e5e5;
    // height: 22.875rem;

    .homeActus-item {
      box-shadow: -5px 5px 10px rgba(0, 0, 0, .2);

      .homeActus-img{
        height: 150px;
        background-color: #fff;
        text-align: center;
  
        img{
          height: 125px;
          width: auto;
        }
      }
  
    }

    .knowMore{
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
      color: #000;
      text-transform: uppercase;
    }

  }