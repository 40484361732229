html {
  font-size: 13px;
}
body {
	color: $black;
  font-family: 'Poppins';
  margin: 0px;
  background-color: #f0f1f3;
}

.bg-grey{
  background-color: #f5f4f5;
}

.m-x-0{
  margin-left: 0;
  margin-right: 0;
}

@include media-breakpoint-down(lg) {
  .container {
    max-width: 100% !important;
  }
}

/* ************************************************************************************************* */
// BACKOFF
a.tabledrag-handle .handle {
  height: 25px !important;
  width: 25px !important;
}

.main{
  position: relative;
}

.navbar{
  padding:0px;
  z-index: 2;
  
  &::after{
    content: " ";
    position: absolute;
    width:100%;
    height: 15px;
    bottom: -15px;
    left: 0px;
    box-shadow: 0px 8px 8px -8px #ccc inset;
}
}

.nav-tabs{
  border: none;
}

.main-container{
	background-color: #ffffff;
	padding:0px;
}


/* ********************************************************************************************** */
/* ********************************************************************************************** */
/* *****************************     COMMON  PAGES     ****************************************** */
/* ********************************************************************************************** */
/* ********************************************************************************************** */
#main_body{
  margin-top: 86px; //margin-top Menu
}


.PageTop{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  padding-top: 10vh;

  &--BlocTitle{
    background-color:#000;
    color: #FFF;
    padding: 25px;
    width: 460px;

    h1{
      text-transform: uppercase;
      font-size: 1.92307692rem;
      font-weight: bold;
    }
  }

}

.MainContainer{
  margin-top:-150px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  min-height: 500px;
}

.footer_BlacklBar{
  background-color:#000;
  color: #FFF;

  &_left{
    line-height: 50px;
    padding: 10px 0px;
  }
  &_right{
      line-height: 50px;
      padding: 10px 0px;
      a{
        color: #FFF;
        i{
          vertical-align: sub;
        }
      }
  }
}

.footer_Menu{
  .menuTitle{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .footer_Menu_link{
    text-transform: uppercase;
    display: block;
    font-weight: bold;
    font-size: 1rem;
    text-decoration: none;
    color: #000;
    margin: 15px 0px;
  }

  p{
    color: #000;
    font-size: 0.8rem;
  }

  ul{
    padding: 0px;
    margin: 0px;
    list-style: none;

    li{
      line-height: 0.9rem;
      padding: 3px 0px;

      a{
        text-decoration: none;
        color: #000;
        font-size: 0.8rem;
        line-height: 0.9rem;

        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}






// ------------------------------
// MEDIAS QUERY  >> MOBILE <<
// ------------------------------
@include media-breakpoint-down(sm) {
  .PageTop{
    height: 195px;
  }
  .MainContainer{
    margin-top:0px;
    padding-right:0px;
    padding-left:0px;
  }
  .PageContent--BlocSsTitle{
    padding-top: 15px !important;
    span{
      font-size: 1.23076923rem !important;
    }
    .PageContent--BlocSsTitleTxt{
      font-size: 1rem !important;
    }
  }
}
@include media-breakpoint-down(lg) {
  .PageContent--BlocSsTitle{
    span{
      //font-size: 18px !important;
    }
  }
}

// ------------------------------
// PAGE CONTENT BLOC TITLE MOBILE
// ------------------------------
.PageContent--BlocTitle--Mobile{
  padding-top: 15px;

  h1{
    text-transform: uppercase;
    font-size: 1.38461538rem;
    font-weight: bold;
  }
  p{
    font-size: 1rem;
  }
}

// ------------------------------
// PAGE CONTENT BLOC SS TITLE
// ------------------------------
.PageContent--BlocSsTitle{

  padding-top: 50px;

  span{
    font-size: 1.92307692rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 25px;
  }
  .PageContent--BlocSsTitleTxt{
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
  }
  hr{
    margin-top: 0.75rem;
    border: 0;
    border-top: 3px solid #000;
    width: 40px;
    margin-left: 0px;
  }

}






// **********     TEMPLATE 01   **********************
// ***************************************************
.tpl01{

  margin-top: 30px;
  padding-bottom: 30px;

  .tpl01-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .div-flottant {
    left: -80px;
    background-color: #fff;
    z-index: 2;
    padding: 25px;
  }

  h2, p{
    width: calc(100% + 80px);
  }

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  .TitleNumber{
    margin-bottom: 15px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  }
  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: left;
    text-decoration: underline;
    padding-top: 3px;

    a{
      color: #000;
    }
  }


  @include media-breakpoint-down(sm) {
    .div-flottant {
      left: 0px !important;
      padding: 15px;
    }
    h2, p{
      width: 100% !important;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }

}

// **********     TEMPLATE 02   **********************
// ***************************************************
.tpl02{

  margin-top: 50px;
  padding-bottom: 50px;


  .tpl02-img {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
    padding-top: 3px;
  }
  .TitleNumber{
    margin-bottom: 15px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  }
  .tpl02--ShadowPicture {
    background-color: #eeebeb;
    bottom: -30px;
    position: absolute;
    height: 100%;
    left: 45px;
    right: -30px;
    width: 100%;

    span{
      bottom: 7px;
      font-size: 0.76923077rem;
      font-style: italic;
      position: absolute;
      right: 35px;
      text-decoration: underline;
    }
  }

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }

}

// **********     TEMPLATE 03   **********************
// ***************************************************
.tpl03{

  margin-top: 100px;
  padding-bottom: 50px;

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }

  .tpl--BlocText{
    padding: 30px 0px 30px 50px;
  }


  .tpl03-img {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;

    span{
      bottom: 0px;
      font-size: 0.76923077rem;
      font-style: italic;
      position: absolute;
      text-decoration: underline;
      display: block;
      width: 100%;
      background-color: #fff;
      padding: 7px 0px 5px 5px;
    }
  }

  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
    padding-top: 3px;
  }

  .tpl03--ShadowPicture {
    background-color: #eeebeb;
    bottom: 60px;
    position: absolute;
    height: 100%;
    left: -95px;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    .tpl--BlocText{
      padding: 15px;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }
}

// **********     TEMPLATE 04   **********************
// ***************************************************
.tpl04{

  margin-top: 50px;
  padding-bottom: 100px;

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }

  .tpl--BlocText{
    padding-left: 50px;
  }

  .div-flottant {
    left: -80px;
    background-color: #fff;
    z-index: 2;
    padding: 25px;
    margin:50px 0px 50px 0px;
  }
  .TitleNumber{
    margin-bottom: 15px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  }
  .tpl04-img {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
    padding-top: 3px;
  }
  
  .tpl04--ShadowPicture {
    background-color: #eeebeb;
    bottom: -30px;
    left: -10px;
    position: absolute;
    height: 100%;
    width: 100%;

    span{
      bottom: 7px;
      font-size: 0.76923077rem;
      font-style: italic;
      position: absolute;
      left: 25px;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-down(sm) {
    .tpl--BlocText{
      padding: 15px;
    }
    .div-flottant {
      left: 0px !important;
      padding: 15px;
      margin:0px;
    }
    h2, p{
      width: 100% !important;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }
  @include media-breakpoint-between(md, lg) {
    h2, p{
      width: calc(100% + 80px) !important;
    }
  }
}

// **********     TEMPLATE 05   **********************
// ***************************************************
.tpl05{

  margin-top: 50px;
  padding-bottom: 100px;

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }

  .tpl--BlocText{
    padding-left: 50px;
  }

  .div-flottant {
    right: -80px;
    background-color: #fff;
    z-index: 2;
    padding: 25px;
    margin:50px 0px 50px 0px;
  }

  .tpl05-img {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
    padding-top: 3px;
  }
  
  .tpl05--ShadowPicture {
    background-color: #eeebeb;
    bottom: -30px;
    left: 45px;
    position: absolute;
    height: 100%;
    width: 100%;

    span{
      bottom: 7px;
      font-size: 0.76923077rem;
      font-style: italic;
      position: absolute;
      right: 35px;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-down(sm) {
    .tpl--BlocText{
      padding: 15px;
    }
    .div-flottant {
      left: 0px !important;
      padding: 15px;
      margin:0px;
    }
    h2, p{
      width: 100% !important;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }
}

// **********     TEMPLATE 06   **********************
// ***************************************************
.tpl06{

  margin-top: 50px;
  padding-bottom: 50px;

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  .TitleNumber{
    margin-bottom: 15px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  }
  .tpl06-img {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
  }
  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
    padding-top: 3px;
  }

  @include media-breakpoint-down(sm) {
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }
}

// **********     TEMPLATE 07   **********************
// ***************************************************
.tpl07{

  margin-top: 50px;
  padding-bottom: 50px;

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  .TitleNumber{
    margin-bottom: 15px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  }
  .tpl07-img {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
  }
  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: left;
    text-decoration: underline;
    padding-top: 3px;
  }

  @include media-breakpoint-down(sm) {
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }
}

// **********     TEMPLATE 08   **********************
// ***************************************************
.tpl08{

  margin-top: 30px;
  padding-bottom: 30px;

  .tpl08-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .div-flottant {
    right: -80px;
    background-color: #fff;
    z-index: 2;
    padding: 25px;
  }

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: -80px;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
    margin-left: -80px;
  }
  .TitleNumber{
    margin-bottom: 15px;
    margin-left: -80px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  }
  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
    padding-top: 3px;
  }


  @include media-breakpoint-down(sm) {
    .div-flottant {
      left: 0px !important;
      padding: 0px 15px 0px 15px;
    }
    h2, p, .TitleNumber{
      width: 100% !important;
      margin-left: 0px;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }

}

// **********     TEMPLATE 09   **********************
// ***************************************************
.tpl09{

  margin-top: 30px;
  padding-bottom: 100px;

  .tpl09-img {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .tpl--BlocText {
    padding: 30px 0px 30px 50px;
  }

  .tpl09--ShadowPicture {
    background-color: #eeebeb;
    bottom: -50px;
    left: -95px;
    position: absolute;
    height: 100%;
    width: 100%;

    span{
      bottom: 25px;
      font-size: 0.76923077rem;
      font-style: italic;
      position: absolute;
      left: 110px;
      text-decoration: underline;
    }
  }

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  .TitleNumber{
    margin-bottom: 15px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  }
  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
    padding-top: 3px;
  }


  @include media-breakpoint-down(sm) {
    .tpl--BlocText {
      padding: 15px;
      padding-top: 0px;
    }
    h2, p, .TitleNumber{
      width: 100% !important;
      margin-left: 0px;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }

}

// **********     TEMPLATE 10   **********************
// ***************************************************
.tpl10{

  margin-top: 50px;
  padding-bottom: 100px;

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  .TitleNumber{
    margin-bottom: 15px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  }
  .tpl--BlocText{
    padding: 30px 0px 30px 50px;
  }

  .tpl10-img {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
    padding-top: 3px;
  }
  
  .tpl10--ShadowPicture {
    background-color: #eeebeb;
    bottom: -30px;
    left: -10px;
    position: absolute;
    height: 100%;
    width: 100%;

    span{
      bottom: 7px;
      font-size: 0.76923077rem;
      font-style: italic;
      position: absolute;
      left: 25px;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-down(sm) {
    .tpl--BlocText{
      padding: 15px;
      padding-top: 0px;
    }
    h2, p{
      width: 100% !important;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }
}

// **********     TEMPLATE 11   **********************
// ***************************************************
.tpl11{

  margin-top: 50px;
  padding-bottom: 100px;

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  .TitleNumber{
    margin-bottom: 15px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  }
  .tpl--BlocText{
    padding: 30px 0px 30px 50px;
  }

  .tpl11-img {
    width: 100%;
    height: 170px;
    position: relative;
    background-size: cover;
    background-position: center;

    span{
      bottom: -25px;
      font-size: 0.76923077rem;
      font-style: italic;
      position: absolute;
      text-decoration: underline;
      display: block;
      width: 100%;
      padding: 7px 0px 5px 5px;
      text-align: right;
    }
  }

  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
    padding-top: 3px;
  }
  
  .tpl11--ShadowPicture {
    background-color: #eeebeb;
    bottom: 43px;
    left: 65px;
    position: absolute;
    height: 100%;
    width: 100%;

    span{
      bottom: 7px;
      font-size: 0.76923077rem;
      font-style: italic;
      position: absolute;
      left: 25px;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-down(sm) {
    .tpl--BlocText{
      padding: 15px;
      padding-top: 0px;
    }
    h2, p{
      width: 100% !important;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }
}

// **********     TEMPLATE 12   **********************
// ***************************************************
.tpl12{

  h2{
    font-size: 1.59rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  .TitleNumber{
    margin-bottom: 15px;
    span{
      font-size: 2.69230769rem;
      font-weight: 800;
      border-bottom: 3px solid #000;
    }
  } 


  @include media-breakpoint-down(sm) {
    .tpl--BlocText{
      padding: 15px;
      padding-top: 0px;
    }
    h2, p{
      width: 100% !important;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }
}

// **********     TEMPLATE 13   **********************
// ***************************************************
.tpl13{

  h3{
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15px;
  }
  h3:first-child {
    margin-top: 0px;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  img{
    border: 1px solid #CCC;
  }

}

// **********     TEMPLATE 14   **********************
// ***************************************************
.tpl14{

  h3{
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15px;
  }
  h3:first-child {
    margin-top: 0px;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  img{
    border: 1px solid #CCC;
  }

}

// **********     TEMPLATE 15   **********************
// ***************************************************
.tpl15{

  h2{
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }
  img{
    border: 1px solid #CCC;
  }

}





/* ********************************************************************************************** */
/* ********************************************************************************************** */
/* *****************************     HISTOIRE       ****************************************** */
/* ********************************************************************************************** */
/* ********************************************************************************************** */

.blocHistoire{

  padding: 40px 0px 10px 0px;

  span{
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
  }
  &--Container{
    padding:30px;
    background-color: #EEEBEB;
  }

}


/* ********************************************************************************************** */
/* ********************************************************************************************** */
/* *****************************     SEARCH       ****************************************** */
/* ********************************************************************************************** */
/* ********************************************************************************************** */

.ListSearch{

  margin-bottom: 70px;

  > div{
    margin-top: 10px;
  }

    h2{
      font-size: 1.69230769rem;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
      text-decoration: none;

      a{
        font-size: 1.69230769rem;
        font-weight: bold;
        text-transform: uppercase;
        color: #000;
        text-decoration: none;
        &:hover{
          text-decoration: none;
        }
      }
    }
    hr{
      margin-top: 0rem;
      margin-bottom: 0rem;
      border: 0;
      border-top: 3px solid #000;
      width: 40px;
      margin-left: 0px;
    }

}


/* ********************************************************************************************** */
/* ********************************************************************************************** */
/* *****************************     PARTENAIRES       ****************************************** */
/* ********************************************************************************************** */
/* ********************************************************************************************** */

.ListingPartners{

  margin-top: 50px;
  margin-bottom: 100px;

  > div{
    margin-bottom: 75px;;
  }

  &--BlocShadow{
    background-color: #eeebeb;
    position: absolute;
    width: calc(100% - 30px);
    height: 100%;
    bottom: -30px;
    left: -20px;
  }
  &--BlocShadowD{
    background-color: #eeebeb;
    position: absolute;
    width: calc(100% - 30px);
    height: 100%;
    top: -30px;
    right: -20px;
  }
  &--Bloc{
    border: solid 0.0625rem #c2c2c2;
    background-color: #ffffff;
    position: relative;
    padding: 25px;

    &-Link{
      margin-bottom: 20px;
      padding-bottom: 20px;;
      display: block;
      border-bottom: solid 0.0625rem #c2c2c2;

      img{
        height: 60px !important;
      }
    }

    &-Name{
      button{
        color: #ffffff;
        background-color: #000;
        border-color: #000;
        border-radius: 0;
        text-transform: uppercase;
        font-size: 0.8rem;
        margin-top: 10px;
        &:hover{
          background-color: #000;
        }
      }

    }

  }

  &--Web{

    span{
      padding-right: 10px;
      font-size: 0.9rem !important;
    }
    a{
      font-size: 0.8rem;
      color: #000;
    }
  }

  &--BlocList{
    margin-bottom: 0px;

    span{
      padding-right: 10px;
    }
    
    dt{
      margin-top: 10px;
      font-size: 0.9rem;;
    }
    dd{
      padding-left: 27px;
      margin-bottom: 0px;
      font-size: 0.9rem !important;
      line-height: 16px
    }

    &-Name{
      max-height: 75px;

    }

  }

}

.memberFilter{
  input{
    color: #ffffff;
    background-color: #000;
    border-color: #000;
    border-radius: 0;
    border: 0px;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 6px 10px 6px 10px;
    vertical-align: middle;
    margin-bottom: 1px;
    margin-left: 10px;
    &:hover{
      background-color: #000;
    }
  }

  select{
    margin-left: 10px;
    padding: 5px;
  }
}



.formContent-BlocChiffres{

  margin-top: 100px;
  padding-bottom: 50px;

  .TitleFiligrane{
    position: absolute;
    top: -90px;
    left: -50px;
    font-size: 82px;
    font-weight: 800;
    color: #EEEBEB;
  }

  h2{
    font-size: 1.69230769rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  hr{
    margin-top: 0rem;
    margin-bottom: 0rem;
    border: 0;
    border-top: 3px solid #000;
    width: 40px;
    margin-left: 0px;
  }
  p{
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
  }

  .tpl--BlocText{
    padding: 0px 0px 0px 50px;

    .icons{
      text-align: left;
      span{
        font-size: 4rem;
      }
    }

    h3{
      font-size: 35px;
      font-weight: bold;
      margin: 0px;
    }
  }

  .formContent-BlocChiffres-img {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;

    span{
      bottom: 0px;
      font-size: 0.76923077rem;
      font-style: italic;
      position: absolute;
      text-decoration: underline;
      display: block;
      width: 100%;
      background-color: #fff;
      padding: 7px 0px 5px 5px;
    }
  }

  .legendPicture{
    font-size: 0.76923077rem;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
    padding-top: 3px;
  }

  .formContent-BlocChiffres--ShadowPicture {
    background-color: #eeebeb;
    bottom: 60px;
    position: absolute;
    height: 100%;
    left: -95px;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    .tpl--BlocText{
      padding: 15px;
    }
    h2{
      font-size: 1.23076923rem !important;
      font-weight: bold;
    }
  }
}

.formContent-BlocTexte{
  h2{
    font-size: 1.69230769rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  hr{
    margin-top: 0.75rem;
    border: 0;
    border-top: 3px solid #000;
    width: 40px;
    margin-left: 0px;
  }

}





/* ********************************************************************************************** */
/* ********************************************************************************************** */
/* *****************************       CONTACT         ****************************************** */
/* ********************************************************************************************** */
/* ********************************************************************************************** */

.FormMembres, .FormPartenaires, .FormContact{

  margin-top: 50px;
  margin-bottom: 50px;


      #contact-sofrev input[type="text"], #contact-sofrev select {
        font-family: 'Poppins';
        width:100%;
        border: 1px solid black;
        padding:0.2em;
      }

      #contact-sofrev input[type="submit"]{
        font-family: 'Poppins';
        color:white;
        background-color:#333333;
        border: 0;
        text-transform: uppercase;
        padding: 10px 35px;
      }

      #contact-sofrev label {
        margin:0;
        font-size:0.8em;
      }

      #contact-sofrev .contact_information{
        margin-bottom:1em;
        font-size:0.9em;
      }

      #contact-sofrev .contact_disclaimer{
        font-size:0.9em;
      }

      #contact-sofrev .form-type-radio {
        display:inline-block;
      }

      #contact-sofrev .form-item-field-contact-donnee{
        margin:0;
      }

}


/* ********************************************************************************************** */
/* ********************************************************************************************** */
/* *****************************       CHANTIERS         **************************************** */
/* ********************************************************************************************** */
/* ********************************************************************************************** */
//Listing Chantier /////////////////////////
.chantierItems{
  padding-top: 20px;

  h2{
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 10px 0px 10px 0px;
    a{
      color: #000;
      &:hover{
        text-decoration: none;
      }
    }
  }

  &--date{

  }
  &--desc{
    padding-bottom: 15px;
  }
  &--link a{
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
    text-decoration: underline;
  }
}


//Fiche Chantier /////////////////////////
.chantierContent {

  .PageContent--Chantier{

    padding-top: 50px;
    padding-bottom: 100px;

    span{
      font-size: 1.92307692rem;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 25px;
    }
    p{
      font-size: 1rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
    }
    hr{
      margin-top: 0.75rem;
      border: 0;
      border-top: 3px solid #000;
      width: 40px;
      margin-left: 0px;
    }

    #carouselChantiers{
      margin: 30px 0px 40px 0px;
    }

    .chantierDate{
      span{
        font-size: 1rem !important;
        font-weight: normal;
      }
    }

  }
  .chantierNavigation{
    padding-top: 60px;

    img{
      // display: block;
    }
    a{
      text-transform: uppercase;
      text-decoration: none;
      color: #000;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .chantierContent{
    margin-top:0px;
    padding-right:0px;
    padding-left:0px;
  }
}


/* ********************************************************************************************** */
/* ********************************************************************************************** */
/* *****************************       ACTUALITES         *************************************** */
/* ********************************************************************************************** */
/* ********************************************************************************************** */

//Listing Actualites /////////////////////////
.actuItems{
  padding-top: 20px;

  h2{
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 10px 0px 10px 0px;
    a{
      color: #000;
      &:hover{
        text-decoration: none;
      }
    }
  }

  &--date{

  }
  &--desc{
    padding-bottom: 15px;
  }
  &--link a{
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
    text-decoration: underline;
  }
}


//Fiche Actu /////////////////////////
.actuContent {

  .PageContent--Actu{

    padding-top: 50px;
    padding-bottom: 100px;

    span{
      font-size: 1.92307692rem;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 25px;
    }
    p{
      font-size: 1rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
    }
    hr{
      margin-top: 0.75rem;
      border: 0;
      border-top: 3px solid #000;
      width: 40px;
      margin-left: 0px;
    }

    #carouselActus{
      margin: 30px 0px 40px 0px;
    }

    .actuDate{
      span{
        font-size: 1rem !important;
        font-weight: normal;
      }
    }

    .video-container {
      position: relative;
      padding-bottom: 56.25%;
      /* ratio 16/9 */
      height: 0;
      overflow: hidden;
      clear: both;
    }
    
    .video-container iframe,
    .video-container object,
    .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }
  .actuNavigation{
    padding-top: 60px;

    img{
      // display: block;
    }
    a{
      text-transform: uppercase;
      text-decoration: none;
      color: #000;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}



@include media-breakpoint-down(sm) {
  .actuContent{
    margin-top:0px;
    padding-right:0px;
    padding-left:0px;
  }
}

