#header {
  position: fixed;
  padding: 0px;
  width: 100%;
  // margin-top: 30px; // pour menu DRUPAL
  top:0px;
  z-index: 5;

  .navbar{
    height: 86px;
    padding-left: 10px;
    background: #fff; 

    .header_baseline {
      span{
        text-transform: uppercase;
        font-size: 16px;
        font-weight: normal;
        line-height: 10px;
      }
      @include media-breakpoint-down(sm) {
        span{
          text-transform: uppercase;
          font-size: 12px;
          font-weight: normal;
          line-height: 10px;
        }
      }
    }
    .header_form{
      .header_form_input{
        outline: 0;
      }
      .header_form_btn{
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        border-left: none;
        background-color: #FFF;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        outline: 0;
      }
    }




    // Hamburger menu
    .hamburger {
      padding: 15px 15px;
      outline: 0;
      display: inline-block;
      cursor: pointer;
      transition-property: opacity, filter;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      font: inherit;
      color: inherit;
      text-transform: none;
      background-color: transparent;
      border: 0;
      margin: 0;
      overflow: visible; }
      .hamburger:hover {
        opacity: 0.7; }
      .hamburger.is-active:hover {
        opacity: 0.7; }
      .hamburger.is-active .hamburger-inner,
      .hamburger.is-active .hamburger-inner::before,
      .hamburger.is-active .hamburger-inner::after {
        background-color: #000; }
    
    .hamburger-box {
      width: 40px;
      height: 24px;
      display: inline-block;
      position: relative; }
    
    .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: -2px; }
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        width: 40px;
        height: 4px;
        background-color: #000;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease; }
      .hamburger-inner::before, .hamburger-inner::after {
        content: "";
        display: block; }
      .hamburger-inner::before {
        top: -10px; }
      .hamburger-inner::after {
        bottom: -10px; }

        .hamburger--slider .hamburger-inner {
          top: 2px; }
          .hamburger--slider .hamburger-inner::before {
            top: 10px;
            transition-property: transform, opacity;
            transition-timing-function: ease;
            transition-duration: 0.15s; }
          .hamburger--slider .hamburger-inner::after {
            top: 20px; }
        
        .hamburger--slider.is-active .hamburger-inner {
          transform: translate3d(0, 10px, 0) rotate(45deg); }
          .hamburger--slider.is-active .hamburger-inner::before {
            transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
            opacity: 0; }
          .hamburger--slider.is-active .hamburger-inner::after {
            transform: translate3d(0, -20px, 0) rotate(-90deg); }
  }


  .menu{
    padding-left: 20px;
    padding-right:20px;

    .menuTitle{
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 16px;
      padding-bottom: 10px;
    }

    ul{

      list-style: none;
      padding: 0;
      margin: 0;
      padding-bottom: 20px;

      li{
        padding: 4px 0px 4px 0px;
        
        a{
          color: #000;
          text-decoration: none;
          display: block;
          line-height: 15px;

          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }


}
