/*♪┏(°.°)┛┗(°.°)┓♪ Fontfaces ♪┏(°.°)┛┗(°.°)┓┗♪*/


/*♪┏(°.°)┛┗(°.°)┓♪ Fonticons ♪┏(°.°)┛┗(°.°)┓┗♪*/

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?bktmqf');
  src:  url('fonts/icomoon.eot?bktmqf#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?bktmqf') format('truetype'),
    url('fonts/icomoon.woff?bktmqf') format('woff'),
    url('fonts/icomoon.svg?bktmqf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-francemarker:before {
  content: "\e90a";
}
.icon-stock:before {
  content: "\e903";
}
.icon-calend:before {
  content: "\e904";
}
.icon-building:before {
  content: "\e905";
}
.icon-pelle:before {
  content: "\e906";
}
.icon-group:before {
  content: "\e907";
}
.icon-euro:before {
  content: "\e908";
}
.icon-euro-sign:before {
  content: "\e909";
}
.icon-home:before {
  content: "\e900";
}
.icon-phone:before {
  content: "\e901";
}
.icon-arobase:before {
  content: "\e902";
}
.icon-user:before {
  content: "\e971";
}
.icon-web:before {
  content: "\e9c9";
}
  

/*♪┏(°.°)┛┗(°.°)┓♪ Font families ♪┏(°.°)┛┗(°.°)┓┗♪*/

